<template>
    <div id="modalComponent">
        <mds-modal
            :title="title"
            :width="width"
            v-model="toggleModalComponent"
            @mds-modal-dismissed="closeSharePopUp"
            aria-labelledby="title-id"
        >
            <template>
                <mds-form>
                    <mds-layout-grid>
                        <mds-row>
                            <mds-col :cols="8">
                                <mds-combo-box
                                    name="filter"
                                    class="checkbox"
                                    placeholder="Search for people"
                                    label="Name"
                                    v-model="selectUser"
                                    :value="getselectionOfUser.text"
                                    :data-set="getselectionOfUser"
                                />
                            </mds-col>
                            <mds-col :cols="4">
                                <mds-select
                                    label="Access"
                                    v-model="accessInput"
                                    placeholder="Select..."
                                >
                                    <option
                                        v-for="(
                                            option, index
                                        ) in getOptionsforAccess"
                                        :key="index"
                                        :value="option.text"
                                    >
                                        {{ option.text }}
                                    </option>
                                </mds-select>
                            </mds-col>
                        </mds-row>
                        <mds-row>
                            <mds-col
                                :cols="12"
                                class="data-set-share-popup-add-button"
                            >
                                <mds-button
                                    :disabled="!isAddShareUserButtonDisable"
                                    variation="secondary"
                                    type="button"
                                    @click="addSharedUser"
                                >
                                    Add
                                </mds-button>
                            </mds-col>
                        </mds-row>
                        <mds-row>
                            <mds-col :cols="12">
                                <div
                                    class="data-set-share-popup-vertical-line"
                                ></div>
                                <div
                                    class="data-set-share-popup-share-heading"
                                    v-if="getShareDataSetData.length > 0"
                                >
                                    Shared with
                                </div>
                            </mds-col>
                        </mds-row>
                        <mds-row class="data-set-share-table">
                            <mds-col :cols="12">
                                <mds-table row-hover>
                                    <mds-thead>
                                        <mds-th style="width: 70%"></mds-th>
                                        <mds-th style="width: 25%"></mds-th>
                                        <mds-th style="width: 5%"></mds-th>
                                    </mds-thead>
                                    <mds-tbody>
                                        <mds-tr
                                            v-for="(
                                                item, i
                                            ) in getShareDataSetData"
                                            :key="i"
                                        >
                                            <mds-td class="tableDataRow">{{
                                                item.name
                                            }}</mds-td>
                                            <mds-td class="tableDataRow">{{
                                                item.access
                                            }}</mds-td>
                                            <mds-td class="tableDataRow">
                                                <template
                                                    v-if="
                                                        item.button === 'delete'
                                                    "
                                                >
                                                    <span
                                                        ><mds-button
                                                            @click="
                                                                removeSingleShareAccess(
                                                                    item.name
                                                                )
                                                            "
                                                            class="actionButton"
                                                            icon="trash"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                </template>
                                            </mds-td>
                                        </mds-tr>
                                    </mds-tbody>
                                </mds-table>
                            </mds-col>
                        </mds-row>
                        <mds-row>
                            <mds-col :cols="12">
                                <div
                                    class="data-set-share-popup-vertical-line"
                                ></div>
                            </mds-col>
                        </mds-row>
                        <mds-row>
                            <mds-col :cols="6">
                                <mds-button
                                    @click="closeModalComponent"
                                    variation="secondary"
                                    type="button"
                                >
                                    Cancel
                                </mds-button>
                            </mds-col>
                            <mds-col
                                :cols="6"
                                class="data-set-share-popup-save-button"
                            >
                                <mds-button
                                    @click="$emit('saveModalComponent')"
                                    type="button"
                                    variation="primary"
                                    :disabled="!saveButtonDisable"
                                >
                                    Save
                                </mds-button>
                            </mds-col>
                        </mds-row>
                    </mds-layout-grid>
                </mds-form>
            </template>
        </mds-modal>
    </div>
</template>

<script>
/* eslint-disable */
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsModal from "@mds/modal";
import MdsForm from "@mds/form";
import MdsSection from "@mds/section";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsComboBox from "@mds/combo-box";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import MdsSelect from "@mds/select";

export default {
    name: "modal-component",
    components: {
        MdsModal,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsButton,
        MdsButtonContainer,
        MdsComboBox,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsSelect,
        MdsSection,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "",
        },
        selectMultiRowData: {
            type: Array,
            default: [],
        },
        getShareDataSetData: {
            type: Array,
            default: [],
        },
        getselectionOfUser: {
            type: Array,
            default: [],
        },
        toggleModalComponent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isAddShareUserButtonDisable() {
            return this.accessInput && this.selectUser.length;
        },
        saveButtonDisable() {
            return this.isAddNewUser;
        },
    },
    data() {
        return {
            selectUser: [],
            accessInput: "",
            getOptionsforAccess: [
                { text: "Edit", value: 1 },
                { text: "Read Only", value: 2 },
            ],
            isAddNewUser: false,
        };
    },

    methods: {
        closeModalComponent() {
            this.selectUser = [];
            this.accessInput = "";
            this.$emit("close");
        },
        addSharedUser() {
            const selectedItemToShare = {
                selectedUser: this.selectUser[0],
                accessInput: this.accessInput,
            };
            this.isAddNewUser = true;
            this.$emit("addSharedUser", selectedItemToShare);
            this.selectUser = [];
            this.accessInput = "";
        },
        closeSharePopUp() {
            this.selectUser = [];
            this.accessInput = "";
            this.isAddNewUser = false;
            this.$emit("close");
        },
        removeSingleShareAccess(name) {
            this.isAddNewUser = true;
            this.$emit("selectedEntityDeleteButton", name);
        },
    },
};
</script>

<style lang="scss" scoped>
.data-set-share-popup-add-button {
    text-align: right;
    margin-top: 16px;
}

.data-set-share-popup-vertical-line {
    border-bottom: 1px solid #cccccc;
    margin: 16px 0px 8px 0px;
}

.data-set-share-popup-share-heading {
    margin-bottom: 16px;
}

.data-set-share-popup-save-button {
    text-align: right;
}
.data-set-share-table {
    height: 300px;
    overflow-y: auto;
}
#modalComponent::v-deep
    .mds-section___VueMDS3Demo
    .mds-section--primary___VueMDS3Demo.mds-section--border-bottom___VueMDS3Demo
    .mds-section__actions___VueMDS3Demo
{
    padding-top: 0;
    display: none;
}
</style>
