<template>
  <!-- <div> -->
  <mds-popover
    :title="title"
    :titleHidden="popOverSelection === 'sharePopover'"
    :triggered-by="triggerBy"
    v-model="toggle"
    auto-position
    :width="width"
    :position="position"
    @mds-popover-dismissed="$emit('close')"
  >
    <mds-textarea
      v-if="popOverSelection == 'dataManagement'"
      hidden-label
      label="Hidden Label"
      placeholder="Hidden Label"
    ></mds-textarea>
    <mds-list-group
      variation="selection"
      v-if="popOverSelection === 'sharePopover'"
    >
      <mds-list-group-item
        text="Share"
        @mds-list-item-clicked="$emit('clickOnShareData')"
      ></mds-list-group-item>
      <mds-list-group-item
        text="Publish"
        @mds-list-item-clicked="$emit('clickOnPublishData')"
      ></mds-list-group-item>
    </mds-list-group>
  </mds-popover>
  <!-- </div> -->
</template>
<script>
import MdsPopover from "@mds/popover";
import MdsTextarea from "@mds/textarea";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";

export default {
  components: {
    MdsPopover,
    MdsTextarea,
    MdsListGroup,
    MdsListGroupItem,
  },
  props: {
    triggerBy: {
      type: String,
      required: true,
    },
    popOverSelection: {
      type: String,
    },
    title: {
      type: String,
    },
    position: {
      type: String,
      default: "bottom-center",
    },
    width: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      toggle: true,
    };
  },
};
</script>
